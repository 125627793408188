import { useContext } from 'react';
import back from '../images/Section3/back.jpg';
// import cel from '../images/Section3/cel.jpg';
// import ipad from '../images/Section3/ipad.jpg';
import { LangContext } from '../context/LangContext';

import c1 from '../images/Section3/c1.jpg';
// import c2 from '../images/Section3/c2.jpg';
import p1 from '../images/Section3/p1.jpg';
// import p2 from '../images/Section3/p2.jpg';

/** ABOUT PAGE */
export const AboutUs = () => {

  const [language] = useContext(LangContext);
  return (
    <>

        <div className="flex flex-col">
            <div className="">
                <img src={c1} className="object-cover w-screen block md:hidden" alt=''/>
                <img src={p1} className="object-cover w-screen hidden md:block lg:hidden" alt=''/>
                
                
                <div className='hidden lg:flex'>

                    <img src={back} className="object-cover w-screen h-screen hidden lg:block" alt=''/>

                    <div className='absolute flex flex-col h-96 lg:h-screen w-screen justify-evenly pt-10'>

                        <div className='w-auto lg:w-1/2 px-10 md:px-16 lg:px-24'>
                            <p className='text-title font-montbold 
                            text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc3text1 }</p>
                        {/* </div>
                        
                        <div className='w-auto lg:w-1/2 pl-24'> */}
                            <p className='font-montmed text-white text-justify
                            text-sm sm:text-sm md:text-base lg:text-lg'><br /><br />{ language.secc3text2 }</p>
                        {/* </div>
                        <div className='w-auto lg:w-1/2 pl-24 bg-yellow-500'> */}
                            <p className='font-montmed text-white text-justify
                            text-sm sm:text-sm md:text-base lg:text-lg'><br />{ language.secc3text3 }</p>
                        </div>

                        {/* <div className='flex justify-end mx-24'>
                                <button className="font-montmed text-white bg-linea rounded-[30px] 
                                hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                        lg:text-xs md:text-base text-xs
                                        xl:w-44 md:w-52 sm:w-44 w-32
                                        xl:h-10 md:h-10 sm:h-10 h-8
                                    ">
                                    { language.secc5lang1 }
                                </button>
                        </div> */}
                    </div>
                </div>   
            </div>

            <div className="bg-cover bg-center h-full py-10 block lg:hidden
                bg-[url('./images/Section3/c2.jpg')] md:bg-[url('./images/Section3/p2.jpg')]">
                    <div className='px-10 md:px-16'>
                        <p className='text-title font-montbold text-justify
                        text-lg sm:text-lg md:text-xl lg:text-3xl '>{ language.secc3text1 }</p>
                    </div>

                    <div className='px-10 md:px-16 my-10'>
                        <p className='font-montmed text-white text-justify
                        text-sm sm:text-sm md:text-base lg:text-lg'>{ language.secc3text2 }</p>
                    </div>
                    <div className='px-10 md:px-16 my-10'>
                        <p className='font-montmed text-white text-justify
                        text-sm sm:text-sm md:text-base lg:text-lg'>{ language.secc3text3 }</p>
                    </div>

                    <div className='flex justify-end px-10 md:px-16'>
                            <button className="font-montmed text-white bg-linea rounded-[30px] 
                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                    lg:text-xs md:text-base text-xs
                                    xl:w-44 md:w-52 sm:w-44 w-32
                                    xl:h-10 md:h-10 sm:h-10 h-8
                                ">
                                { language.secc5lang1 }
                            </button>
                    </div>
            </div>
        </div>




        
        {/* <div className="grid auto-rows-max mx-auto justify-items-center items-center">
            <img src={c1} class="object-cover w-screen block md:hidden" alt=''/>
            <img src={p1} class="object-cover w-screen hidden md:block lg:hidden" alt=''/>

            <article className='grid'>
                <img src={c2} class="object-cover w-screen block md:hidden lg:hidden" alt=''/>
                <img src={p2} class="object-cover w-screen hidden md:block lg:hidden" alt=''/>
                <img src={back} class="object-cover w-screen h-screen hidden lg:block" alt=''/>

                <div className='absolute flex flex-col h-96 lg:h-screen mx-10 md:mx-16 lg:mx-24 justify-evenly w-auto lg:w-1/2'>
                    <div>
                        <p className='text-title font-montbold
                        text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc3text1 }</p>
                    </div>

                    <div>
                        <p className='font-montmed text-white
                        text-xs sm:text-sm md:text-base lg:text-lg'>{ language.secc3text2 } <br /><br /> { language.secc3text3 } </p>
                    </div>

                    <div className='flex justify-end'>
                            <button className="font-montmed text-white bg-linea rounded-[30px] 
                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                    lg:text-xs md:text-base text-xs
                                    xl:w-44 md:w-52 sm:w-44 w-32
                                    xl:h-10 md:h-10 sm:h-10 h-8
                                ">
                                { language.secc5lang1 }
                            </button>
                    </div>
                </div>
            </article>
        </div> */}
    </>
  )
}
